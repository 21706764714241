import Arrow from "@/assets/icons/arrow-top-right.svg";
import useLocale from "@/hooks/useLocale";
import localizePath from "@/functions/localizePath";

export default function BlogCard({
  img,
  style,
  category,
  title,
  info,
  url_slug,
}) {
  const locale = useLocale();

  return (
    <a
      href={localizePath(`/newsroom/${url_slug}`, locale)}
      className={`max-w-[413px] lg:max-w-none w-full group/item cursor-pointer swiper-item featured-blog`}
    >
      <div className="relative 3xl:w-full">
        <div className="bg-pink h-12 w-12 hidden group-hover/item:flex items-center justify-center absolute right-0 bottom-0 p-[6px] z-10">
          <Arrow
            className={`${
              style?.arrow ?? "[&>path]:fill-purple [&>path]:stroke-purple"
            }`}
          />
        </div>
        <img
          src={img ?? ""}
          alt="image"
          width={413}
          height={607}
          className="group-hover/item:opacity-60 h-full w-full max-h-[570px] object-cover blog_img_height"
        />
        <div className="absolute flex gap-2 left-[21px] top-[18px]">
          {category.split(",").map((i) => (
            <div
              key={i}
              className="text-purple text-base bg-grey-400 px-6 py-3"
            >
              {i.trim()}
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-[413px] lg:max-w-none w-full pt-6 group-hover/item:text-white-light">
        <p
          className={`${style?.txt} font-normal h-30 text-xl md:text-[30px]/10 whitespace-pre-line group-hover/item:opacity-50`}
          style={{
            textOverflow: "ellipsis",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            overflow: "hidden",
          }}
        >
          {title}
        </p>
        <p
          className={`${style?.txt} font-normal h-[60px] md:h-20 text-base md:text-xl/[30px] pt-4 group-hover/item:opacity-50`}
          style={{
            textOverflow: "ellipsis",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
          }}
        >
          {info}
        </p>
      </div>
    </a>
  );
}
